/**
 * @file Static Card Component
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { DarkerGray } from '#/constants/colors'

const Container = styled.div`
  padding: 24px;
  background-color: ${DarkerGray};
  border-radius: 18px;
`

type Props = {
  children: React.ReactNode
  style?: React.CSSProperties
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const StaticCard = ({ children, style, onClick }: Props) => (
  <Container style={style} onClick={onClick}>
    {children}
  </Container>
)

export default StaticCard
