/**
 * @file Scratch to redeem overlay
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Text from '#/components/atoms/Text'
import ScratchCard from '#/components/atoms/ScratchCard'
import { OffBlack } from '#/constants/colors'
import useUpdateDealClaim from '#/hooks/query/deal/useUpdateDealClaim'
import confetti from 'canvas-confetti'
import { motion } from 'framer-motion'

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${OffBlack};
  padding: 16px;
  padding: 40px;
`

type Props = {
  dealClaim: DealClaim
  onScratched?: () => void
}

const ScratchToRedeemOverlay = ({
  dealClaim,
  onScratched = () => {},
}: Props) => {
  const { mutateAsync: updateDealClaimMutateAsync } = useUpdateDealClaim()

  const handleScratched = () => {
    try {
      updateDealClaimMutateAsync({
        dealClaimID: dealClaim.id,
        updateObject: { scratched: true },
      })
      onScratched()
      confetti({ particleCount: 150, spread: 100 })
    } catch {
      // do some error handling here
    }
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Text type="h4" style={{ marginBottom: 20, textAlign: 'center' }}>
        Scratch to reveal your reward!
      </Text>
      <ScratchCard
        scratched={dealClaim.scratched}
        onScratched={handleScratched}
      />
    </Container>
  )
}

export default ScratchToRedeemOverlay
