/**
 * @file Scratch Card Component
 * @author Alwyn Tan
 */

import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import reward from '#/images/reward.png'
import freedrink from '#/images/freedrink.png'
import { AnimatePresence, motion } from 'framer-motion'

const Container = styled.div`
  position: relative;
  border-radius: 10px;
`

const Canvas = styled(motion.canvas)`
  object-fit: none;
  position: absolute;
  z-index: 1;
`

const BackImage = styled.img`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
`

type Props = {
  style?: React.CSSProperties
  width?: number
  height?: number
  scratched: boolean
  onScratched: () => void
}

const ScratchCard = ({
  style,
  width = 250,
  height = 250,
  scratched = false,
  onScratched = () => {},
}: Props) => {
  const [showCanvas, setShowCanvas] = useState(!scratched)
  const scratchThresholdReachedRef = useRef(false)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const isDrawingRef = useRef(false)
  const lastPointRef = useRef({ x: 0, y: 0 })

  const scratchStart = (e: MouseEvent | TouchEvent) => {
    e.preventDefault()
    const { offsetX, offsetY } = e

    isDrawingRef.current = true
    lastPointRef.current = { x: offsetX, y: offsetY }
  }

  const scratchEnd = (e: MouseEvent | TouchEvent) => {
    e.preventDefault()
    isDrawingRef.current = false
  }

  const scratch = useCallback(
    (e: PointerEvent) => {
      e.preventDefault()
      const { offsetX, offsetY } = e

      // Only test every `stride` pixel. `stride`x faster,
      // but might lead to inaccuracy
      const getPercentFilled = (stride: number = 1) => {
        if (!canvasRef.current) return 0
        const ctx = canvasRef.current.getContext('2d')
        if (!isDrawingRef.current || !ctx) return 0

        const pixels = ctx.getImageData(0, 0, width, height)
        const pdata = pixels.data
        const l = pdata.length
        const total = l / stride
        let count = 0

        // Iterate over all pixels
        for (let i = count; i < l; i += stride) {
          if (pdata[i] === 0) count += 1
        }

        return Math.round((count / total) * 100)
      }

      if (!canvasRef.current) return
      const ctx = canvasRef.current.getContext('2d')
      if (!isDrawingRef.current || !ctx) return

      ctx.globalCompositeOperation = 'destination-out'
      ctx.beginPath()
      ctx.moveTo(lastPointRef.current.x, lastPointRef.current.y)
      ctx.lineTo(offsetX, offsetY)
      ctx.closePath()
      ctx.stroke()

      lastPointRef.current = { x: offsetX, y: offsetY }

      const percentFilled = getPercentFilled(32)

      if (percentFilled > 50 && !scratchThresholdReachedRef.current) {
        scratchThresholdReachedRef.current = true
        setShowCanvas(false)
        onScratched()
      }
    },
    [height, width, onScratched]
  )

  useLayoutEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')

    canvas.addEventListener('mousedown', scratchStart)
    canvas.addEventListener('mouseup', scratchEnd)

    canvas.addEventListener('touchstart', scratchStart)
    canvas.addEventListener('touchend', scratchEnd)

    canvas.addEventListener('pointermove', scratch)

    if (!ctx) return
    ctx.fillRect(0, 0, width, height)
    ctx.lineWidth = 60
    ctx.lineJoin = 'round'

    const image = new Image()
    image.src = reward
    image.onload = () => ctx.drawImage(image, 0, 0, width, height)
  }, [height, scratch, width])

  return (
    <Container style={{ ...style, width, height }}>
      <AnimatePresence>
        {showCanvas && (
          <Canvas
            ref={canvasRef}
            id="canvas"
            width={`${width}px`}
            height={`${height}px`}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={e => {
              e.stopPropagation()
            }}
          />
        )}
      </AnimatePresence>
      <BackImage src={freedrink} />
    </Container>
  )
}

export default ScratchCard
