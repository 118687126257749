/**
 * @file Claims a deal mutation
 * @author Alwyn Tan
 */

import { dealKeys } from '#/query-key-factory'
import api from '#/utils/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

type Deal = {
  dropID: string
  type: string // note: change thisto deal types in the future
  expiresOn: Date
  claimed?: { dealID: string }
}

type MutationParams = {
  id: string
  affiliateLinkShortID: string
}

const postClaimDeal = async (id: string, affiliateLinkShortID: string) =>
  api.post('deal/claim', { json: { id, affiliateLinkShortID } }).json<Deal>()

const useClaimDeal = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ id, affiliateLinkShortID }: MutationParams) =>
      postClaimDeal(id, affiliateLinkShortID),
    {
      onSettled(data, error, variables) {
        queryClient.invalidateQueries(dealKeys.detail(variables.id))
      },
    }
  )
}

export default useClaimDeal
