/**
 * @file Short Link Page
 * @author Alwyn Tan
 */

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { navigate, PageProps } from 'gatsby'
import SEO from '#/components/atoms/SEO'
import ReceiverDealPage from '#/components/organisms/receiver/ReceiverDealPage'
import SharerPostPage from '#/components/organisms/sharer/SharerPostPage'
import { AnimatePresence } from 'framer-motion'
import DealOverlay from '#/components/organisms/deal/DealOverlay'
import ScratchToRedeemOverlay from '#/components/organisms/deal/ScratchToRedeemOverlay'
import useGetAffiliateLink from '#/hooks/query/affiliateLink/useGetAffiliateLink'
import useCurrentUser from '#/hooks/query/useCurrentUser'
import useGetDeal from '#/hooks/query/deal/useGetDeal'

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const ShortLinkPage = ({ location, params }: PageProps) => {
  const { shortID } = params
  const { data, isFetched: affiliateLinkFetched } = useGetAffiliateLink(shortID)
  const { drop, link, user } = data || {}
  const { data: currentUser, isFetched: userIsFetched } = useCurrentUser()
  const { data: deal } = useGetDeal(drop?.activeDeal || '')

  useEffect(() => {
    if (affiliateLinkFetched) {
      window.analytics.page(document.title, {
        'Drop Venue Short Code': drop?.venueShortCode,
      })
    }
  }, [affiliateLinkFetched, drop?.venueShortCode])

  useEffect(() => {
    if (
      userIsFetched &&
      affiliateLinkFetched &&
      link?.createdBy !== currentUser?.id &&
      deal?.claimed?.affiliateLinkShortID &&
      location.pathname !== `/${deal?.claimed?.affiliateLinkShortID}`
    ) {
      navigate(`/${deal?.claimed?.affiliateLinkShortID}`)
    }
  }, [
    userIsFetched,
    affiliateLinkFetched,
    deal?.claimed?.affiliateLinkShortID,
    location.pathname,
    link?.createdBy,
    currentUser?.id,
  ])

  if (affiliateLinkFetched && (!link || !drop || !user)) return navigate('/404')

  const SEOTitle = (user && drop && `${user.name} is at ${drop.title}!`) || ''

  if (!affiliateLinkFetched || !link || !drop || !user) return null

  const renderContent = () => {
    if (currentUser?.id === user?.id && deal) {
      return <SharerPostPage venue={drop?.title} deal={deal} />
    }

    return (
      <>
        {(!currentUser || !currentUser.name || (deal && !deal.claimed)) && (
          <DealOverlay
            drop={drop}
            affiliateLinkShortID={link.shortID}
            referrer={user}
          />
        )}
        <AnimatePresence>
          {deal?.claimed && !deal.claimed.used && !deal.claimed.scratched && (
            <ScratchToRedeemOverlay dealClaim={deal.claimed} />
          )}
        </AnimatePresence>
        {deal?.claimed && (
          <ReceiverDealPage dealClaim={deal.claimed} venue={drop.title} />
        )}
      </>
    )
  }

  return (
    <Container>
      <SEO title={SEOTitle} image={drop.thumbnail} />
      {renderContent()}
    </Container>
  )
}

export default ShortLinkPage
