/**
 * @file Deal Overlay Screen
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Text from '#/components/atoms/Text'
import Button from '#/components/molecules/Button'
import { OffBlack } from '#/constants/colors'
import { useAuth } from '#/contexts/global-context'
import useClaimDeal from '#/hooks/query/deal/useClaimDeal'
import { DEAL_TYPES } from '#/constants/deal'
import useGetDeal from '#/hooks/query/deal/useGetDeal'
import DropCardItem from '../drop/DropCardItem'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
  background-color: ${OffBlack};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 24px;
`

type Props = {
  drop: Drop
  affiliateLinkShortID: string
  referrer: User
  onDealClaimed?: () => void
}

const DealOverlay = ({
  drop,
  affiliateLinkShortID,
  referrer,
  onDealClaimed = () => {},
}: Props) => {
  const { data: deal } = useGetDeal(drop.activeDeal)
  const { presentAuthModal } = useAuth()
  const claimDealMutation = useClaimDeal()

  const handleAccessClick = () => {
    presentAuthModal(
      async () => {
        try {
          await claimDealMutation.mutateAsync({
            id: drop.activeDeal,
            affiliateLinkShortID,
          })
          onDealClaimed()
        } catch (e) {
          // can't redeem it,
          // error handle?
        }
      },
      {
        nameSubtitle: 'So the restaurant knows who you are',
      }
    )
  }

  const renderDealType = () => {
    switch (deal?.type) {
      case DEAL_TYPES.FREE_DRINK:
        return 'Free Drink'
      case DEAL_TYPES.FREE_DESSERT:
        return 'Free Dessert'
      default:
        return 'Free Item'
    }
  }

  return (
    <Container>
      <DropCardItem fan style={{ height: 292, width: 182 }} drop={drop} />
      {/* Todo: render this active deal dynamically */}
      <Text type="h1" style={{ marginTop: 38 }}>
        {renderDealType()} on Us
      </Text>
      <Text
        type="h5"
        style={{ textAlign: 'center', marginTop: 28, marginBottom: 24 }}
      >
        {referrer?.name} is at {drop.title}.
        <br />
        <br />
        Join their INSIDER CLUB to unlock secret access the next time you come
        in 👀
      </Text>
      <Button size="small" onClick={handleAccessClick}>
        Get Access
      </Button>
    </Container>
  )
}

export default DealOverlay
