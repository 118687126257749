/**
 * @file Updates a deal claim
 * @author Alwyn Tan
 */

import { dealKeys } from '#/query-key-factory'
import api from '#/utils/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

type DealClaimResponseJSON = {
  dealClaim: DealClaim
}

type DealClaimUpdateObject = {
  used?: boolean
  scratched?: boolean
}

type UseUpdateDealClaimMutationProps = {
  dealClaimID: string
  updateObject: DealClaimUpdateObject
}

const putUpdateDealClaim = async (
  dealClaimID: string,
  updateObject: DealClaimUpdateObject
) =>
  api
    .put(`deal/claim/${dealClaimID}`, { json: updateObject })
    .json<DealClaimResponseJSON>()

const useUpdateDealClaim = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async ({ dealClaimID, updateObject }: UseUpdateDealClaimMutationProps) =>
      putUpdateDealClaim(dealClaimID, updateObject),
    {
      onSuccess: result => {
        queryClient.invalidateQueries(dealKeys.detail(result.dealClaim.dealID))
      },
    }
  )
}

export default useUpdateDealClaim
