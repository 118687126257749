/**
 * @file Drop Card Item
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import Text from '#/components/atoms/Text'

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: inherit;
  border-radius: 20px;
`

const InfoContainer = styled.div`
  padding: 20px 20px 24px 20px;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Gradient = styled.div`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  border-radius: 18px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 61.98%,
    rgba(0, 0, 0, 0.5) 84.58%,
    rgba(0, 0, 0, 0.5) 100%
  );
`

const DropImage = styled.img`
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  object-fit: cover;
  position: absolute;
  border-radius: inherit;
  filter: drop-shadow(2px 2px 10px #000000);
  opacity: 0.5;
`

const DropVideo = styled.video`
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  object-fit: cover;
  position: absolute;
  border-radius: inherit;
  filter: drop-shadow(2px 2px 10px #000000);
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`

const BulletContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: normal;
  color: white;
  white-space: pre-line;
`

type Props = {
  style?: React.CSSProperties
  showInfo?: boolean
  fan?: boolean
  drop: Drop
}

const DropCardItem = ({ style, fan, showInfo, drop }: Props) => {
  const renderDescriptions = () => {
    const bullets = drop.description
      .trim()
      .split('\n')
      .map(c => c.replace(/^- /g, ''))

    return (
      <Description>
        {bullets.map((curr, index) => (
          <BulletContainer
            style={{ marginTop: index !== 0 ? 12 : 0 }}
            key={`bullet-${index}`} // eslint-disable-line react/no-array-index-key
          >
            <Text style={{ paddingRight: 10 }}>•</Text>
            <Text>{curr}</Text>
          </BulletContainer>
        ))}
      </Description>
    )
  }

  return (
    <Container style={style}>
      {showInfo && (
        <InfoContainer>
          <Gradient />
          <Text type="h4">{drop.title}</Text>
          {renderDescriptions()}
        </InfoContainer>
      )}
      {fan && (
        <DropImage
          src={drop.thumbnail}
          style={{ transform: 'rotate(3.95deg)' }}
        />
      )}
      {fan && (
        <DropImage
          src={drop.thumbnail}
          style={{ transform: 'rotate(-0.55deg)' }}
        />
      )}
      <DropVideo
        src={drop.video}
        poster={drop.thumbnail}
        muted
        autoPlay
        playsInline
        loop
        style={{ transform: fan ? 'rotate(-5.05deg)' : 'unset' }}
      />
    </Container>
  )
}

export default DropCardItem
