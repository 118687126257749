/**
 * @file Gets information about a deal
 * @author Alwyn Tan
 */

import { dealKeys } from '#/query-key-factory'
import api from '#/utils/api'
import { useQuery } from '@tanstack/react-query'

type DealResponseJSON = {
  deal: {
    dropID: string
    type: string // note: change thisto deal types in the future
    expiresOn: Date
    claimed?: DealClaim
  }
}

const getDeal = async (id?: string) =>
  api.get(`deal/${id}`).json<DealResponseJSON>()

const useGetDeal = (id: string) =>
  useQuery(dealKeys.detail(id), () => getDeal(id), {
    enabled: !!id,
    select: data => data.deal,
  })

export default useGetDeal
