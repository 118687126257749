/**
 * @file Sharer's posting page
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import styled from 'styled-components'
import Text from '#/components/atoms/Text'
import StaticCard from '#/components/molecules/StaticCard'
import Button from '#/components/molecules/Button'
import Portal from '#/components/atoms/Portal'
import { AnimatePresence } from 'framer-motion'
import InstructionVideoOverlay from '#/components/atoms/InstructionVideoOverlay'
import instagramInstructionsVideo from '#/videos/instagram_instructions.mp4'
import { toast } from 'react-hot-toast'
import { DEAL_TYPES } from '#/constants/deal'
import Page from '#/components/templates/Page'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const CardTextContainer = styled.div`
  display: flex;
  flex-direction: row;

  > *:first-child {
    padding-right: 5px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
`

const InstructionalVideo = styled.video`
  border-radius: 10px;
  height: 90px;
  width: 70px;
  margin: 10px 0 4px 0;
  object-fit: contain;
  padding: 0 15px;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 0.5px solid #ffffff1a;
  padding: 12px 24px;
  margin-top: auto;
`

const Content = styled.div`
  padding: 10px 20px 20px 20px;
  overflow: auto;
`

type Props = {
  venue: string
  deal: {
    type: string // note: change thisto deal types in the future
  }
}

const SharerPostPage = ({ venue, deal }: Props) => {
  const [showInstagramInstructions, setShowInstagramInstructions] =
    useState(false)

  const fullLink = window !== undefined ? window.location.href : ''
  const shortLink =
    window !== undefined
      ? `${window.location.host}${window.location.pathname}`
      : ''

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(fullLink)
    toast.success('Link Copied!')
  }

  const handleHideInstructionsClick = (
    e: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => {
    e?.preventDefault()
    e?.stopPropagation()
    setShowInstagramInstructions(false)
  }

  const handleShowInstructionsClick = (
    e: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => {
    e?.preventDefault()
    e?.stopPropagation()
    setShowInstagramInstructions(true)
  }

  const renderDealType = () => {
    switch (deal?.type) {
      case DEAL_TYPES.FREE_DRINK:
        return 'free drink'
      case DEAL_TYPES.FREE_DESSERT:
        return 'free dessert'
      default:
        return 'free item'
    }
  }

  return (
    <Page title="Time to Post">
      <Container>
        <Content>
          <StaticCard
            style={{ marginBottom: 10 }}
            onClick={handleShowInstructionsClick}
          >
            <Row>
              <CardTextContainer>
                <Text type="button2">1.</Text>
                <Text type="button2">
                  Post a video of {venue} to your Instagram Story with Disco
                  magic link
                </Text>
              </CardTextContainer>
              <Col>
                <InstructionalVideo
                  src={instagramInstructionsVideo}
                  muted
                  autoPlay
                  playsInline
                  loop
                />
                <Text type="p2" style={{ textAlign: 'center', opacity: 0.5 }}>
                  See how <br />
                  (tap here)
                </Text>
              </Col>
            </Row>
          </StaticCard>
          <StaticCard style={{ marginBottom: 10 }}>
            <Row>
              <CardTextContainer>
                <Text type="button2">2.</Text>
                <Text type="button2">
                  Show your post to a server and get your {renderDealType()}!
                </Text>
              </CardTextContainer>
            </Row>
          </StaticCard>
        </Content>
        <Footer>
          <Text
            type="button1"
            style={{
              flex: 1,
              color: '#807E7E',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              minWidth: 0,
            }}
          >
            {shortLink}
          </Text>
          <Button onClick={handleCopyLinkClick}>Copy My Link</Button>
        </Footer>
      </Container>
      <Portal>
        <AnimatePresence>
          {showInstagramInstructions && (
            <InstructionVideoOverlay
              vidSrc={instagramInstructionsVideo}
              onClose={handleHideInstructionsClick}
            />
          )}
        </AnimatePresence>
      </Portal>
    </Page>
  )
}

export default SharerPostPage
