/**
 * @file Receiver's deal page
 * @author Alwyn Tan
 */

import React, { useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import Text from '#/components/atoms/Text'
import StaticCard from '#/components/molecules/StaticCard'
import Button from '#/components/molecules/Button'
import { AnimatePresence } from 'framer-motion'
import AlertModal from '#/components/molecules/AlertModal'
import useUpdateDealClaim from '#/hooks/query/deal/useUpdateDealClaim'
import confetti from 'canvas-confetti'
import { StaticImage } from 'gatsby-plugin-image'
import Page from '#/components/templates/Page'

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  padding: 10px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;

  > * {
    flex: 1;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
`

const InnerCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const InfoContainer = styled.div`
  max-width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const RedeemedContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

type Props = {
  dealClaim: DealClaim
  venue: string
}

const ReceiverDealPage = ({ dealClaim, venue }: Props) => {
  const [showAlert, setShowAlert] = useState(false)
  const { mutateAsync: updateDealClaimMutateAsync } = useUpdateDealClaim()

  const handleUseClaimedDeal = (
    e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      updateDealClaimMutateAsync({
        dealClaimID: dealClaim.id,
        updateObject: { used: true },
      })
      confetti({ particleCount: 150, spread: 100 })
      setShowAlert(false)
    } catch {
      // do some error handling here
    }
  }

  const handleUseDealClick = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setShowAlert(true)
  }

  const handleNotThereYetClick = (
    e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setShowAlert(false)
  }

  if (!dealClaim || !venue) return null

  return (
    <Page title="Congrats!">
      <Container>
        <Content>
          <StaticCard>
            <CardContainer>
              <InnerCardContainer
                style={{ opacity: dealClaim.used ? 0.25 : 1 }}
              >
                <Text type="h4" style={{ textAlign: 'center' }}>
                  You’re in the {venue} Insider Club
                </Text>
                <span style={{ fontSize: 100, padding: '20px 0 20px 0' }}>
                  🎉
                </span>
                <InfoContainer>
                  <Text type="button2" style={{ textAlign: 'center' }}>
                    To use your reward:
                    <br />
                    <br />
                    Visit {venue} and show this page to a server
                  </Text>
                  <Button
                    disabled={dealClaim.used}
                    onClick={handleUseDealClick}
                    size="small"
                    style={{
                      height: 'auto',
                      padding: '10px 0 10px 0',
                      width: '100%',
                      marginTop: 20,
                    }}
                  >
                    I’m at {venue}!
                    <br />
                    Use my Deal
                  </Button>
                  <Text
                    type="p2"
                    style={{ textAlign: 'center', paddingTop: 15 }}
                  >
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    We also texted this link to you so you can find it easily
                    next time
                  </Text>
                </InfoContainer>
              </InnerCardContainer>
              {dealClaim.used && (
                <RedeemedContainer>
                  <StaticImage
                    src="../../../images/discoman-green.png"
                    alt="greendiscoman"
                    height={120}
                    width={120}
                    objectFit="contain"
                    style={{ marginBottom: 10 }}
                  />
                  <Text type="h2">REDEEMED</Text>
                  {dealClaim?.usedOn && (
                    <Text type="h5" style={{ padding: 5 }}>
                      on {dayjs(dealClaim.usedOn).tz().format('MMM DD, h:mma')}
                    </Text>
                  )}
                </RedeemedContainer>
              )}
            </CardContainer>
          </StaticCard>
        </Content>
        <AnimatePresence>
          {showAlert && (
            <AlertModal
              title="Stop! 👮‍♀️🚨"
              subtitle={`You can only redeem your reward once you get to ${venue}. Are you there right now?`}
              positiveText={`Yes I’m at ${venue} 🚦`}
              negativeText="NO I’m not there yet ✋"
              onPositiveClick={handleUseClaimedDeal}
              onNegativeClick={handleNotThereYetClick}
            />
          )}
        </AnimatePresence>
      </Container>
    </Page>
  )
}

export default ReceiverDealPage
