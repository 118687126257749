/**
 * @file Gets data about the given affiliate link via its short ID
 * @author Alwyn Tan
 */

import { affiliateLinkKeys } from '#/query-key-factory'
import api from '#/utils/api'
import { useQuery } from '@tanstack/react-query'

type AffiliateLinkResponseJSON = {
  link?: AffiliateLink
  drop?: Drop
  user?: User
}

const getAffiliateLink = async (shortID: string) =>
  api.get(`affiliate/${shortID}`).json<AffiliateLinkResponseJSON>()

const useGetAffiliateLink = (shortID: string) =>
  useQuery(
    affiliateLinkKeys.detail({ shortID }),
    async () => getAffiliateLink(shortID),
    { onSuccess: () => {} }
  )

export default useGetAffiliateLink
